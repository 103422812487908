<template>
  <el-container>
    <el-main class="p-0">
      <div class="form-wrapper expense-accounts-form">
        <div class="edit-form-wrapper">
          <div class="info-text">
            <h2>{{ $t('pages.safe_management.booking.form.header') }}</h2>
            <h3>{{ $t('pages.safe_management.booking.form.from') }}</h3>
            <div>{{ computedName }}</div>
            <h3>
              {{ $t('pages.safe_management.booking.form.current_value') }}
            </h3>
            <div>{{ currentAmount }}</div>
            <h3>{{ $t('pages.safe_management.booking.form.limit_lower') }}</h3>
            <div>{{ lowerLimit }}</div>
          </div>
          <el-row :gutter="0">
            <el-col
              class="form-column"
              :xs="24"
              :sm="24"
              :md="24"
              :lg="24"
              :xl="24"
            >
              <el-form
                ref="form"
                v-loading="loading"
                :model="form"
                :rules="rules"
                label-position="top"
                label-width="120px"
                class="edit-form"
              >
                <el-form-item
                  :label="
                    $t('pages.safe_management.booking.form.properties.to.label')
                  "
                  prop="to"
                  required
                >
                  <el-select
                    v-model="form.to"
                    :placeholder="
                      $t(
                        'pages.safe_management.booking.form.properties.to.placeholder'
                      )
                    "
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>

                <el-form-item prop="items">
                  <el-form-item
                    :label="
                      $t(
                        'pages.safe_management.booking.form.properties.amount.label'
                      )
                    "
                    required
                    class="currency-form-item"
                  >
                    <th-currency-select
                      v-model="form.items[0].currency"
                      :allowed-currencies="allowedCurrencies"
                      @update:modelValue="updateCurrency"
                    />
                  </el-form-item>
                  <el-form-item width="20px">
                    <th-currency-input
                      v-model="form.items[0].amount"
                      :currency="form.items[0].currency"
                    />
                  </el-form-item>
                </el-form-item>

                <el-form-item
                  prop="comment"
                  width="20px"
                  class="comment-form-item"
                >
                  <el-input
                    v-model="form.comment"
                    type="textarea"
                    :placeholder="
                      $t(
                        'pages.safe_management.booking.form.properties.comment.placeholder'
                      )
                    "
                  />
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-main>
    <el-footer class="footer">
      <div class="actions">
        <el-button @click="$emit('close')">
          {{ $t('common.interactions.buttons.cancel') }}
        </el-button>
        <el-button type="primary" @click="submitForm('form')">
          {{ $t('common.interactions.buttons.save') }}
        </el-button>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import Ajv from 'ajv'
import safeGet from 'just-safe-get'
import itemsSchema from '../helpers/items.schema.json'

const ajv = new Ajv({ multipleOfPrecision: 2 })
const validate = ajv.compile(itemsSchema)

export default {
  props: {
    row: {
      type: Object,
      required: true
    },
    resources: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      required: true
    },
    allowedCurrencies: {
      type: Array,
      required: true
    },
    transferType: {
      type: String,
      required: true
    },
    refreshTable: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      form: this.genInitialForm(),
      loading: false,
      rules: {
        to: [
          {
            required: true,
            message: this.$t(
              'pages.safe_management.booking.form.rules.to.required'
            ),
            trigger: 'blur'
          }
        ],
        comment: [
          {
            max: 1024,
            message: this.$t('common.forms.rules.max_length', { max: 1024 })
          }
        ],
        items: [{ validator: this.validateItems, trigger: 'blur' }]
      }
    }
  },
  computed: {
    options() {
      if (this.type === 'safe_to_safe') return this.formatOptions('safes')
      if (this.type === 'safe_to_bank') return this.formatOptions('banks')
      if (this.type === 'safe_to_expense') return this.formatOptions('expenses')
      return []
    },
    computedName() {
      return `${this.row.account_number} ${
        this.row.name ? `(${this.row.name})` : ''
      }`
    },
    currentAmount() {
      if (
        !Array.isArray(this.row.items) ||
        !this.row.items.length ||
        !this.row.items
      )
        return '-'
      return this.$formatCurrency(
        this.row.items[0].amount,
        this.row.items[0].currency
      )
    },
    lowerLimit() {
      let limit = 0

      if (
        Array.isArray(this.row.limit_lower) &&
        this.row.limit_lower.length &&
        this.row.limit_lower
      ) {
        limit = this.$formatCurrency(
          this.row.limit_lower[0].amount,
          this.row.limit_lower[0].currency
        )
      }

      return limit
    }
  },
  watch: {
    form:
      process.env.NODE_ENV === 'production'
        ? undefined
        : {
            deep: true,
            handler: function (val) {
              this.$log.debug(JSON.stringify(val, null, 2))
            }
          }
  },
  methods: {
    genInitialForm() {
      return {
        to: null,
        items: [
          {
            amount: null,
            currency: this.allowedCurrencies[0]
          }
        ],
        comment: null
      }
    },
    // this is a custom validator for "items" in form, because async-validator library used by element-ui
    // does not provide the deep validation for objects in arrays that we need in this case
    validateItems(rule, value, callback) {
      const valid = validate(value)

      if (!valid) {
        this.$logException(validate.errors)
        callback(
          new Error(
            this.$t(
              'pages.safe_management.booking.form.field_warnings.transfer_value_zero'
            )
          )
        )
      } else {
        callback()
      }
    },
    updateCurrency(currency) {
      this.form.items[0].currency = currency
    },
    makeHandleableBody() {
      const now = new Date().toISOString()

      let result = {
        ...this.form,
        transfer_type: this.transferType,
        initiated_at: now,
        from: this.row.id,
        issuer: 'Dashboard' // Note: in the future this will be replaced with the staff uuid
      }

      // remove invalid item objects
      result.items = result.items.filter(
        (item) =>
          item.currency && Number.isFinite(item.amount) && item.amount > 0
      )

      if (!result.items.length) {
        result = null

        this.$logException('There are no valid elements left in form.items', {
          trackError: false,
          message: this.$t(
            'pages.safe_management.booking.form.messages.validation_fail.message'
          )
        })
      }

      return result
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return this.$notify.error({
            title: this.$t(
              'pages.safe_management.booking.form.messages.validation_fail.title'
            ),
            message: this.$t(
              'pages.safe_management.booking.form.messages.validation_fail.message'
            )
          })
        }

        this.book(this.row.id)
      })
    },
    async book(id) {
      this.loading = true

      const inst = th.safes()
      const body = this.makeHandleableBody()

      if (!body) {
        return this.$notify.warning({
          title: this.$t(
            'pages.safe_management.booking.form.messages.validation_fail.title'
          ),
          message: this.$t(
            'pages.safe_management.booking.form.messages.validation_fail.message'
          )
        })
      }

      let resp
      try {
        resp = await inst.book(body)
      } catch (err) {
        const errorMessage =
          safeGet(err, 'properties.error.response.data.msg') ||
          this.$t('pages.safe_management.booking.form.messages.booking_fail')

        return this.$notify.error({
          title: this.$t(
            'pages.safe_management.booking.form.messages.submit_fail.title'
          ),
          message: `Error: ${errorMessage}`
        })
      } finally {
        this.loading = false
      }

      const { data } = resp

      if (data && Array.isArray(data.errors)) {
        data.errors.forEach((error) => {
          this.$message({
            message: error && error.errorDetails,
            type: 'warning'
          })
        })
      }

      this.refreshTable()
      this.$emit('close')
    },
    formatOptions(name) {
      let accountNumberLabel = 'fa_account_number'
      let resource = safeGet(this.resources, name) || []
      if (name === 'safes') {
        resource = resource.filter((safe) => safe.id !== this.row.id)
        accountNumberLabel = 'account_number'
      }
      return (
        resource &&
        resource.map((account) => ({
          value: account.id,
          label: `${account[accountNumberLabel]} ${
            account.name ? `(${account.name})` : ''
          }`
        }))
      )
    }
  }
}
</script>

<style scoped>
.form-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  flex: 1 0 100%;
}

.edit-form-wrapper {
  flex: 2 0 80%;
  height: 100px;
  overflow: auto;
}

.form-column {
  margin: 0;
  padding: 14px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  align-content: center;
  align-items: center;
}

.info-text {
  padding: 14px;
}

.el-form-item :deep(.currency-form-item) {
  margin-bottom: 10px;
}

.el-form-item :deep(.comment-form-item) {
  margin-top: 20px;
}

.footer {
  display: flex;
  justify-items: flex-end;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
}
</style>
