<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      :headers="headers"
      do-route
      :show-operations="false"
      no-meta-check
      :route-base="routeBase"
      resource="safes"
      :resource-query="{
        query: { ...resourceQuery, embed: 'location' }
      }"
      :buttons="computedButtons"
      :show-search-filter="false"
      headers-filterable
      :headers-config="headersConfig"
      :row-menu-options="rowMenuOptions"
      :handle-row-menu-click="handleRowMenuClick"
      :handle-row-menu-open="handleRowMenuOpen"
      row-menu-direction-left
      @loading-error="handleLoadingError"
      @headers-config="handleHeadersConfig"
    />
    <th-modal name="booking-form" height="800px" width="600px">
      <booking-form
        :row="currentRow"
        :type="currentMenuSelection"
        :transfer-type="currentMenuSelection"
        :resources="resources"
        :allowed-currencies="allowedCurrencies"
        :refresh-table="refresh"
        @close="handleClose"
      />
    </th-modal>
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { mapGetters } from 'vuex'
import safeGet from 'just-safe-get'
import BookingForm from './components/booking-form'

export default {
  components: { BookingForm },
  data() {
    return {
      routeBase: '/accounting/safe_management',
      headers: [
        {
          field: 'name',
          label: this.$t('common.headers.name.title'),
          fallback: '-',
          minWidth: 130,
          truncate: true
        },
        {
          field: 'custom_id',
          label: this.$t('pages.safe_management.all.headers.safe_id'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'account_number',
          label: this.$t('pages.safe_management.all.headers.account_number'),
          fallback: '-',
          minWidth: 150,
          truncate: true
        },
        {
          field: 'cost_center',
          label: this.$t('pages.safe_management.all.headers.cost_center'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'location',
          label: this.$t('pages.safe_management.all.headers.location'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          formatter: (row, column) => {
            return (
              safeGet(row, 'location.name') ||
              safeGet(row, 'location.id') ||
              '-'
            )
          }
        },
        {
          field: 'limit_upper',
          label: this.$t('pages.safe_management.all.headers.limit_upper'),
          fallback: '-',
          truncate: true,
          minWidth: 140,
          align: 'right',
          formatter: (row, column) => {
            // TODO: when ready, multi-currency needs to be handled here
            if (!row.limit_upper || !row.limit_upper.length) return '-'
            return this.$formatCurrency(
              row.limit_upper[0].amount,
              row.limit_upper[0].currency
            )
          }
        },
        {
          field: 'limit_lower',
          label: this.$t('pages.safe_management.all.headers.limit_lower'),
          fallback: '-',
          truncate: true,
          minWidth: 140,
          align: 'right',
          formatter: (row, column) => {
            // TODO: when ready, multi-currency needs to be handled here
            if (!row.limit_lower || !row.limit_lower.length) return '-'
            return this.$formatCurrency(
              row.limit_lower[0].amount,
              row.limit_lower[0].currency
            )
          }
        },
        {
          field: 'type',
          label: this.$t('common.headers.type.title'),
          fallback: '-',
          minWidth: 80,
          truncate: true,
          formatter: ({ type }) => {
            return (
              {
                safe: this.$t(
                  'pages.safe_management.edit.form.safe_types.safe'
                ),
                vault: this.$t(
                  'pages.safe_management.edit.form.safe_types.vault'
                )
              }[type] || '--'
            )
          }
        },
        {
          field: 'items',
          label: this.$t('pages.safe_management.all.headers.value'),
          fallback: '-',
          minWidth: 140,
          truncate: true,
          align: 'right',
          formatter: (row, column) => {
            // TODO: when ready, multi-currency needs to be handled here
            if (!row.items || !row.items.length) return '-'
            return this.$formatCurrency(
              row.items[0].amount,
              row.items[0].currency
            )
          }
        }
      ],
      buttons: [
        {
          type: 'create',
          scopes: ['accounting:safe_management:create']
        }
      ],
      selectedItems: [],
      currentRow: {},
      resources: undefined,
      currentMenuSelection: null,
      transferType: null
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation'
    }),
    rowMenuOptions() {
      if (
        !this.$checkPermissions({
          scopes: ['accounting:safe_management:create_safe_booking']
        })
      ) {
        return []
      }

      return [
        {
          label: this.$t('pages.safe_management.all.row-menu.safe_to_bank'),
          value: 'safe_to_bank',
          enabled:
            this.currentRow.items &&
            this.currentRow.items.length &&
            Array.isArray(this.currentRow.items)
        },
        {
          label: this.$t('pages.safe_management.all.row-menu.safe_to_safe'),
          value: 'safe_to_safe',
          enabled:
            this.currentRow.items &&
            this.currentRow.items.length &&
            Array.isArray(this.currentRow.items)
        },
        {
          label: this.$t('pages.safe_management.all.row-menu.safe_to_expense'),
          value: 'safe_to_expense',
          enabled:
            this.currentRow.items &&
            this.currentRow.items.length &&
            Array.isArray(this.currentRow.items)
        },
        {
          label: this.$t('pages.safe_management.all.row-menu.safe_to_pos'),
          value: 'safe_to_pos',
          enabled: false // TODO: Dynamically enable menu based on validity of amount items
        }
      ]
    },
    isEdit() {
      return this.$route.name === 'accounting-safe-management-edit'
    },
    isNew() {
      return this.$route.name === 'accounting-safe-management-new'
    },
    headersConfig() {
      return (
        safeGet(
          this.$store.state.Config.localConfiguration,
          'settings.headerFilters.safes'
        ) || {}
      )
    },
    // allowedCurrencies needs to be passed in as a prop into booking-form
    // because if it were a computed prop in booking-form
    // it cannot be referenced in data, where we need it to set the initial value in currency-select
    allowedCurrencies() {
      return (
        (this.currentRow.items &&
          this.currentRow.items.map((item) => item.currency)) || ['EUR']
      )
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    resourceQuery() {
      return {
        deleted: false,
        active: true,
        branch: this.currentLocation
      }
    }
  },
  created() {
    this.fetchResources()
    this.$emitter.on('refresh-requested', () => {
      this.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    async fetchResources() {
      const expenseInst = th.expenseAccounts()
      try {
        this.resources = await this.$resourceFetch(
          {
            resource: 'safes',
            query: this.resourceQuery
          },
          {
            resource: 'banks',
            handler: () =>
              expenseInst.getAll({ ...this.resourceQuery, type: 'bank' })
          },
          {
            resource: 'expenses',
            handler: () =>
              expenseInst.getAll({
                ...this.resourceQuery,
                accepts_booking_from_safe: true
              })
          }
        )
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t(
            'pages.products.edit.form.errors.fetch.resources.code_XXX.content'
          )
        })
      }
    },
    handleTransferBooked() {
      this.handleCloseBookingForm()
      this.$refs.table.fetch()
    },
    handleSelectionChange(val) {
      this.selectedItems = val
    },
    handleCloseBookingForm() {
      this.$modal.hide('safe-booking')
    },
    refresh() {
      this.$nextTick(() => {
        this.fetchResources()
        this.$refs.table.refresh()
      })
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.safe_management.title')
        })
      })
    },
    handleHeadersConfig(config) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: 'settings.headerFilters.safes',
        value: config || {}
      })
    },
    handleClose() {
      this.$thModal.hide('booking-form')
    },
    handleRowMenuClick(type, row) {
      this.$log.debug('type', type)
      this.$log.debug('row', row)

      this.currentRow = row
      this.currentMenuSelection = type
      this.transferType = type

      this.$thModal.show('booking-form')
    },
    handleRowMenuOpen(row) {
      this.$log.debug('row', row)
      this.currentRow = row
    }
  }
}
</script>

<style scoped></style>
